'use client';
import { ITruckOptionsPayload } from '@truckmap/common';
import { currentUserSettingsAtom } from 'components/common/MapComponent/recoil/mapSettingsAtom';
import { useCurrentUserOptions } from 'hooks/useTruckOptions/useCurrentUserOptions';
import { createContext, useContext, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

export const TruckOptionsContext = createContext({} as ITruckOptionsPayload);

type UserOptions = {
  equipmentWeights: number;
  equipmentHeights: number;
};

export const useTruckOptions = () => {
  const truckOptionsPayload = useContext(TruckOptionsContext);
  const currentUserOptions = useCurrentUserOptions({ truckOptionsPayload }) as UserOptions;
  const setCurrentUserOptionsAtom = useSetRecoilState(currentUserSettingsAtom);

  useEffect(() => {
    if (currentUserOptions) {
      setCurrentUserOptionsAtom(currentUserOptions);
    }
  }, [currentUserOptions]);

  return {
    currentUserOptions,
    truckOptionsPayload
  };
};
